import React from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Intro from "../common/Intro";
import Breadcrumb from "../common/Breadcrumb";
import Offers from "./Offers";
import Advantages from "../common/Advantages";
import CareerInfo from "./CareerInfo";
import { getShareImage } from "../../utils/getShareImage";

import imgSupport from "../../images/common/support.svg";
import imgProgress from "../../images/common/progress.svg";
import imgLocation from "../../images/common/location.svg";

const Career = props => {
  const { lang, t, layoutT, servicesT, postsT, offersT } = props.pageContext;
  const { pathname } = props.location;

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname} withoutLangChange>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} />

      <div className="Career">
        <Offers t={t.offers} lang={lang} offers={offersT} />

        <Advantages t={t.weOffer} images={[imgSupport, imgProgress, imgLocation]} columns3 />

        <CareerInfo t={t.info} />
      </div>
    </Layout>
  );
};

Career.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Career;
