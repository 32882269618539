import React from "react";
import PropTypes from "prop-types";
// import { Link } from "gatsby";
// import LabelText from "../common/LabelText";
import { getHrefs } from "../../utils/langService";

const Offers = ({ t, lang, offers }) => {
  const { pages } = getHrefs(lang);

  return (
    <section className="Offers">
      <div className="container">
        <h2 className="title">{t.title}</h2>

        <p style={{ textAlign: "center" }}>Aktualnie nie posiadamy żadnych ofert pracy.</p>

        {/* <div className="Offers__list">
          {offers.map((elem, i) => (
            <div key={i} className="Offers__item">
              <h4 className="Offers__item__title">{elem.title}</h4>

              <div className="Offers__item__container">
                <div>
                  <LabelText label={t.exp} name={elem.details.exp} />
                  <LabelText label={t.workplace} name={elem.details.workplace} />
                  <LabelText label={t.workingTime} name={elem.details.workingTime} />
                  <LabelText label={t.contractType} name={elem.details.contractType} />
                  <LabelText label={t.salary} name={elem.details.salary} />
                </div>

                <Link className="Offers__item__btn" to={`/${lang}/${pages[1]}/${elem.id}/`}>
                  {t.btn}
                </Link>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
};

Offers.propTypes = {
  t: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  offers: PropTypes.array.isRequired
};

export default Offers;
