import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

const RodoInfo = ({ t }) => {
  const [show, setShow] = useState(false);

  const readMore = useCallback(() => {
    setShow(!show);
  }, [setShow, show]);

  return (
    <div className="RodoInfo">
      <p>{t.text}</p>

      <a role="button" tabIndex="0" className="RodoInfo__readMore" onClick={readMore} onKeyDown={readMore}>
        {t.readMore}
      </a>

      {show && <p>{t.fullText}</p>}
    </div>
  );
};

RodoInfo.propTypes = {
  t: PropTypes.object.isRequired
};

export default RodoInfo;
