import React from "react";
import PropTypes from "prop-types";
import RodoInfo from "../common/RodoInfo";

const CareerInfo = ({ t }) => (
  <section className="Career__info">
    <div className="container Career__info__container">
      <h2 className="title">{t.title}</h2>

      <p className="Career__info__description">{t.description}</p>

      <a className="Career__info__email" href={`mailto:${t.email}`}>
        {t.email}
      </a>

      <RodoInfo t={t.rodoInfo} />
    </div>
  </section>
);

CareerInfo.propTypes = {
  t: PropTypes.object.isRequired
};

export default CareerInfo;
